import React from 'react'
import Layout from '../components/shared/components/Layout'
import SEO from '../components/Seo'
import Button from '../components/shared/components/Button'
import '../css/content.css'
import craveTimeImg from '../assets/images/learn-more/cravetime.png'
import inspector from '../assets/images/learn-more/inspector.png'

const learnMore = () => {
  return (
    <Layout>
      <SEO title="Learn More" />
      <div className="container mx-auto px-8 my-8">
        <article>
          <h1>Learn More</h1>
          <h2>How does it work?</h2>
          <p>
            We use standard front-end optimisations for the first page load. After this, your
            subsequent page loads are close to instant (less than 200ms).
          </p>
          <p>
            How do we get a page load under 200 ms? We can’t change the laws of physics but we’ve
            found that human behavior on a website is predictable. We can often predict where a
            person will click on a page with a 70% accuracy. We use this information to
            intelligently prefetch the most likely links that a person will visit. So when they
            click on one of these links, the content is ready and waiting for them - instantly. Web
            visitors are more engaged, stay longer and buy more.
          </p>

          <h2>How is FasterWeb different?</h2>
          <p>
            We compliment services like Content Delivery Networks (CDNs) and caching (server and
            client-side). It&apos;s important to cache whenever possible. Other services cache based
            on <strong>what you have done</strong>. <i>FasterWeb</i> cache based on what{' '}
            <strong>you are likely to do</strong>.
          </p>
          <p>
            The closer you cache can cache content, the better. Other service caches on the{' '}
            <strong>server and network edges</strong>. <i>FasterWeb</i>{' '}
            <strong>caches content in memory</strong> - basically the fastest place to read cache.
            We only cache HTML (not images and other web assets) so we use minimal memory.
          </p>
          <h2>How can I implement FasterWeb?</h2>
          <p>
            To implement the FasterWeb service you need to add some Javascript to each page of your
            site - something like adding Google Analytics.
          </p>
          <p>We will also provide some recommendations to improve your first page load.</p>
          <p>
            See how it works on this{' '}
            <a href="https://cravetime.com/" target="_blank" rel="noreferrer noopener">
              Demo site
            </a>{' '}
            - prefetched links have an orange border.
          </p>
          <a href="https://cravetime.com/">
            <img src={craveTimeImg} alt="Crave Time" />
          </a>
          <p />
          <p>
            See what’s happening under the covers using the Chrome Inspector (right-click on the
            page and click &quot;inspect&quot;).
          </p>
          <img src={inspector} alt="Crave Time - Chrome Inspector" />
          <p />
          <h2>Why now?</h2>
          <p>
            Web performance is now more important than ever. Do you want a website that’s faster
            than anything that currently exists? Would you like to be one of the first to implement
            this technology?
          </p>

          <p>
            We’re currently working at capacity and our service is not yet ready for everyone but we
            will work with a few select clients.
          </p>
          <h3>Do you qualify? </h3>
          <p>
            We’re looking for sites with:
            <ul>
              <li>relatively little Javascript (easier to implement)</li>
              <li>
                more than 10K page visits per month (for intelligently predicting user behavior)
              </li>
            </ul>
          </p>
          <p>Are you obsessed with speed?</p>
          <Button className="mb-4 pl-6" navigateTo="contact">
            Request an Invite
          </Button>
        </article>
      </div>
    </Layout>
  )
}

export default learnMore
